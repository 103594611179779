import React from "react";
import {  BrowserRouter as Router , Route, Switch } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { compose } from 'recompose';

// core components
import AdminNavbar from "../../components/navBars";
import Footer from "../../components/footer";
import Sidebar from "../../components/sideBar";
// import FixedPlugin from "../../components/fixedPlugin";
import routes from "../../constants/sideRoutes.js";
import logo from "../../assets/img/logo.png";
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from '../sessions';

import * as ROLES from '../../constants/roles';
import { withFirebase } from '../firebase';

import { SampleConsumer } from '../dbSelect';
import { withRouter } from 'react-router-dom';
var ps;

// const AdminPage = () => (
//   <AuthUserContext.Consumer>
//         <AdminManagement/>
//   </AuthUserContext.Consumer>
// );

// const AdminPage = () => (
//         <Admin/>
// );

// const SendsContainer = () => (
//   <SampleConsumer>
//     {
//       ({state, actions}) => (
//         <Sends 
//           dbName={state.dbName}
//           setValue={actions.setValue}
//         />
//       )
//     }
//   </SampleConsumer>
// )

const AdminPage = () => (
        
          <SampleConsumer>
          {
            ({state, actions}) => (
                <Admin 
                  dbName={state.dbName}
                  setValue={actions.setValue}
                />
            )
          }
        </SampleConsumer> 
      

)

// class AdminPage extends React.Component {
  
//   render() {
    
//     return(
//       <div>
//         {/* <SampleConsumer>
//           {
//             ({state, actions}) => (
//                 <Admin 
//                   dbName={state.dbName}
//                   setValue={actions.setValue}
//                 />
//             )
//           }
//         </SampleConsumer> */}
//         <Admin/>
//       </div>
//     )
//   }
// }



class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "primary",
      sidebarOpened: document.documentElement.className.indexOf("nav-open") !== -1 ,
      users : JSON.parse(sessionStorage.getItem('authUser')) 
    };
  }

  
  componentDidMount() {
    
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel, { suppressScrollX: true });
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    } 
    
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if(sessionStorage.getItem('authUser')==undefined) {
      this.props.history.push("/")
    }
    // this.props.firebase.onAuthUserListener( authUser => {
    //   if ( authUser.ROLES == undefined){
    //     this.props.history.push("/")
    //   }
    // })
    if (e.history.action === "PUSH") {
      if (navigator.platform.indexOf("Win") > -1) {
        let tables = document.querySelectorAll(".table-responsive");
        for (let i = 0; i < tables.length; i++) {
          ps = new PerfectScrollbar(tables[i]);
        }
      }
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    this.setState({ sidebarOpened: !this.state.sidebarOpened });
  };
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/home") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            state = {this.props.location.state}
          />
        );
      } else {
        return null;
      }
    });
  };
  handleBgClick = color => {
    this.setState({ backgroundColor: color });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  render() {
    return (
        <div className="wrapper">
          
        <SampleConsumer>
          {
            ({state, actions}) => (
                <Sidebar
                {...this.props}
                routes={routes}
                bgColor={this.state.backgroundColor}
                logo={{
                  outterLink: "/",
                  text: "LG Signature",
                  imgSrc: logo
                }}
                toggleSidebar = {this.toggleSidebar}
                user = {this.state.users}
                dbName = {state.dbName}
                setValue = {actions.setValue}
              />
            )
          }
        </SampleConsumer> 
          <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
            <AdminNavbar
              {...this.props}
              // brandText={this.getBrandText(this.props.location.pathname)}
              brandText="Artience DataStore"
              toggleSidebar={this.toggleSidebar}
              sidebarOpened={this.state.sidebarOpened}
            />
              <Switch>
                {/* <SampleConsumer> */}
                {this.getRoutes(routes)}
                {/* </SampleConsumer> */}
              </Switch>
            {// we don't want the Footer to be rendered on map page
            this.props.location.pathname.indexOf("maps") !== -1 ? null : (
              <Footer fluid />
            )}
          </div>
        </div>

        
    );
  }
}




// const AdminManagement = withFirebase(Admin);

// const  condition = authUser => !!authUser;

// export default compose(
//   withAuthorization(condition),
// )(AdminPage);


// const condition = authUser => authUser && authUser.roles.includes(ROLES.ADMIN);
const condition = authUser => !!authUser;

export default compose(
  withFirebase ,
  withAuthorization(condition),
  withRouter
)(Admin);



// export default Admin;