import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter , Input } from 'reactstrap';
import axios from 'axios';

class ModalSample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false , 
      reportName : ''
    };

    this.toggle = this.toggle.bind(this);
    this.saveReport = this.saveReport.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ reportName: event.target.value});
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }
  saveReport() {
    console.log(this.props.dimensions)
    axios.post(
        process.env.REACT_APP_REQUEST_URL + "/db/select/report/" + this.props.dbNames + "/", 
        {
            "dimensions" : this.props.dimensions,
            "metrics" : this.props.metrics,
            "dbName" : this.props.dbNames,
            "csrfToken" : sessionStorage.getItem('csrfToken').replace('"','').replace('"',''),
            "userName" : JSON.parse(sessionStorage.getItem('authUser'))["username"],
            "reportName" : this.state.reportName
        } ,
        { headers: {
            'Content-Type': 'application/json' ,
            // Cookie : "csrfToken=" + sessionStorage.getItem('csrfToken') + ";"
          }
        }
      ).then(res => {
        console.log(res)
      }).catch((error) => {
        console.log("Error")
    })

    this.setState(prevState => ({
        modal: !prevState.modal
      }));
  }

//   componentDidUpdate() {
//       console.log("Hello")
//       console.log(this.props)
//   }





  render() {
      const closeBtn = <button className="close" onClick={this.toggle}>&times;</button>;

      return (
      <div>
        <button  className = "form-control" style ={this.props.style} onClick={this.toggle}>{this.props.buttonLabel}</button>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle} close={closeBtn}>Report Name</ModalHeader>
          <ModalBody>
            <input type="textarea" onChange={this.handleChange} value={this.state.reportName} placeholder="Please Input Report Name" rows={1} className="form-control" style ={{color:"black"}} />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.saveReport}>Save</Button>{' '}
            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ModalSample;