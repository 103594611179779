// import Dashboard from "../components/dashboard";
import commingSoon from "../components/commingSoon";
import exploreContainer from  "../components/explore";
import UserManagementContainer from  "../components/userManagement";
import DownloadContainer from  "../components/download";
import ReportContainer from  "../components/report";


// import Icons from "views/Icons.jsx";
// import Map from "views/Map.jsx";
// import Notifications from "views/Notifications.jsx";
// import Rtl from "views/Rtl.jsx";
// import TableList from "views/TableList.jsx";
// import Typography from "views/Typography.jsx";
// import UserProfile from "views/UserProfile.jsx";

var sideRoutes = [
  {
    path: "/explore",
    name: "EXPLORE",
    icon: "tim-icons icon-zoom-split",
    component: exploreContainer,
    layout: "/home" , 
    
  },
  {
    path: "/report",
    name: "Report",
    icon: "tim-icons icon-bullet-list-67",
    component: ReportContainer,
    layout: "/home"
  },
  {
    path: "/data-download",
    name: "Data Download",
    icon: "tim-icons icon-upload",
    component: DownloadContainer,
    layout: "/home"
  },
  {
    path: "/user-management",
    name: "User Management",
    icon: "tim-icons icon-single-02",
    component: commingSoon,
    layout: "/home"
  },
  // {
  //   path: "/performance",
  //   name: "Performance by media",
  //   icon: "tim-icons icon-zoom-split",
  //   component: commingSoon,
  //   layout: "/home"
  // },
  // {
  //   path: "/search-trend",
  //   name: "Search Trend",
  //   icon: "tim-icons icon-sound-wave",
  //   component: SearchTrend,
  //   layout: "/home"
  // },
  // {
  //   path: "/account",
  //   name: "Account Management",
  //   icon: "tim-icons icon-bullet-list-67",
  //   component: commingSoon,
  //   layout: "/home"
  // },
  // {
  //   path: "/sample",
  //   name: "Sample",
  //   icon: "tim-icons icon-shape-star",
  //   component: Dashboard,
  //   layout: "/home"
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   rtlName: "الرموز",
  //   icon: "tim-icons icon-atom",
  //   component: Icons,
  //   layout: "/admin"
  // },
  // {
  //   path: "/map",
  //   name: "Map",
  //   rtlName: "خرائط",
  //   icon: "tim-icons icon-pin",
  //   component: Map,
  //   layout: "/admin"
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   rtlName: "إخطارات",
  //   icon: "tim-icons icon-bell-55",
  //   component: Notifications,
  //   layout: "/admin"
  // },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   rtlName: "ملف تعريفي للمستخدم",
  //   icon: "tim-icons icon-single-02",
  //   component: UserProfile,
  //   layout: "/admin"
  // },
  // {
  //   path: "/tables",
  //   name: "Table List",
  //   rtlName: "قائمة الجدول",
  //   icon: "tim-icons icon-puzzle-10",
  //   component: TableList,
  //   layout: "/admin"
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   rtlName: "طباعة",
  //   icon: "tim-icons icon-align-center",
  //   component: Typography,
  //   layout: "/admin"
  // },
  // {
  //   path: "/rtl-support",
  //   name: "RTL Support",
  //   rtlName: "ار تي ال",
  //   icon: "tim-icons icon-world",
  //   component: Rtl,
  //   layout: "/rtl"
  // }
];
export default sideRoutes;
