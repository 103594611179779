import React from "react";
// nodejs library that concatenates classes
import Select from "react-select";
import moment from 'moment';
import axios from 'axios';
import MyVerticallyCenteredModal from './modal'
import firebase from 'firebase';


import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import ReactExport from "react-data-export";

// reactstrap components
import {
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button
} from "reactstrap";

import Spinner from 'react-spinner-material';

import { SampleConsumer } from "../dbSelect";
import PaginationButton from './PaginationButton';

const paddingStyle = { 
  paddingLeft: "5px" ,
  paddingRight: "5px"
}


const sortResults = (data , prop, asc) => {
  var dataName = data.sort(function(a, b) {
      if (asc) {
          return (a[prop] > b[prop]) ? 1 : ((a[prop] < b[prop]) ? -1 : 0);
      } else {
          return (b[prop] > a[prop]) ? 1 : ((b[prop] < a[prop]) ? -1 : 0);
      }
  });
  console.log(dataName)
  return dataName
}
// axios.defaults.withCredentials = true


// Be sure to include styles at some point, probably during your bootstrapping

// core components

const JsonTable = require('ts-react-json-table');

const createJsonDimension = (variable) => {
  const aggs = {}
  if (variable ==="Day") {
    aggs[variable] = {
      "terms": {
        "field": variable  ,
        "size": 100 ,
        "order": {
          "_key": "desc"
        }
      }
    }
  } else {
    aggs[variable] = {
      "terms": {
        "field": variable + ".keyword" ,
        "size": 100 ,
        "order": {
          "_key": "desc"
        }
      }
    }
  }
  return aggs
}

const createJsonMetric = (variable) => {
  var aggs = {}
  aggs[variable] = {
    "sum": {
      "field": variable
    }
  }
  return aggs
}

const createJsonMetric2 = (variable) => {
  var aggs = {
    "sum": {
      "field": variable
    }
  }
  return aggs
}

const makeQuery = ( dimension , metric ,startDate , endDate , pageNum ) => {
    var query = {}
    query["dimensions"] = dimension
    query["metrics"] = metric
    query["startDate"] = startDate
    query["endDate"] = endDate
    query["pageNum"] = pageNum
    query["csrfToken"] = sessionStorage.getItem('csrfToken').replace('"','').replace('"','')
    query["userName"] = sessionStorage.getItem('authUser')["username"]
  
    return query
}

const commaSplit = (srcNumber) =>  {
  var txtNumber = '' + srcNumber;
  var rxSplit = new RegExp('([0-9])([0-9][0-9][0-9][,.])');
  var arrNumber = txtNumber.split('.');
  arrNumber[0] += '.';
      do {
      arrNumber[0] = arrNumber[0].replace(rxSplit, '$1,$2');
      } while (rxSplit.test(arrNumber[0]));
      if (arrNumber.length > 1) {
      return arrNumber.join('');
      }
      else {
      return arrNumber[0].split('.')[0];
  }
}


const intMaker = (number) => {
    const cleanNumber = number * 100 
    return String(cleanNumber.toFixed(0)) + "%"
}




class Download extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      week : "12" ,
      data : [] ,
      showing : true ,
      rawData : [] ,
      startDate : moment(new Date()).day(-30).format("YYYY-MM-DD") ,
      endDate : moment(new Date()).format("YYYY-MM-DD") ,
      dataColumn : [{dataField: "Day", text: "Day"}] ,
      totalResult : 0 ,
      dataDimension : [] ,
      dataMetric : [] , 
      multiValueDimension: [],
      multiValueMetric: [],
      pageNum : 1 , 
      loading : false  , 
      modalShow: false  , 
      downList : [] 
    }
    this.downloadListOn = this.downloadListOn.bind(this)
  }

  downloadList () {
    const downloadJson = firebase.database().ref('/download/').once('value').then(function(snapshot) {
      return snapshot.val()
    });
    
    downloadJson.then( (list) => {
      var dataList = JSON.stringify(list)
      dataList = JSON.parse(dataList)
      var result = [];
      var dataListReal = []
      for(var i in dataList) {
        result.push([i, dataList[i]]); 
      }
      result.map ((index) => {
        dataListReal.push(index[1])
      })
      var dimensionsList
      var metricsList

      dataListReal.map((index) => {
        dimensionsList = ""
        metricsList = ""
        index["dimensions"].map((index2) => {
            dimensionsList = dimensionsList + index2.label + " ,"
        })
        index["dimensions"] = dimensionsList.substr(0,dimensionsList.length-1)
        index["metrics"].map((index2) => {
            metricsList = metricsList + index2.label + " ,"
        })
        index["metrics"] = metricsList.substr(0,metricsList.length-1)
      })
      this.setState({downList : dataListReal ,loading:false})


    // const downloadJson = firebase.database().ref('/download/').on('value', function(snapshot) {
    //   return snapshot.val()
    })

  }

  downloadListOn = (thisis) => {
    firebase.database().ref('/download/').orderByChild('table').equalTo(this.props.dbName).on('value',function(snapshot) {

      var dataList = snapshot.val()
      var result = [];
      var dataListReal = []
      for(var i in dataList) {
        result.push([i, dataList[i]]); 
      }
      result.map ((index) => {
        dataListReal.push(index[1])
      })
      var dimensionsList
      var metricsList

      dataListReal.map((index) => {
        dimensionsList = ""
        metricsList = ""
        index["dimensions"].map((index2) => {
            dimensionsList = dimensionsList + index2.label + " ,"
        })
        index["dimensions"] = dimensionsList.substr(0,dimensionsList.length-1)
        index["metrics"].map((index2) => {
            metricsList = metricsList + index2.label + " ,"
        })
        index["metrics"] = metricsList.substr(0,metricsList.length-1)
      })
      dataListReal = sortResults(dataListReal,"createTime",false)
      thisis.setState({downList : dataListReal ,loading:false})
      console.log(dataListReal)
    })
  }




    componentDidMount() {
      this.setState({ loading: true })
      window.scrollTo(0, 0);
      this.downloadListOn(this)
    } 

    componentDidUpdate(prevProps , prevState){
      if ( this.props.dbName !== prevProps.dbName){
        this.setState({
          multiValueDimension : [] , 
          multiValueMetric : [] , 
          loading: true 
        })
        this.downloadListOn(this)
      }
    }

    handleEvent = (event, picker) => {
      this.setState({
        startDate : moment(picker.startDate).format("YYYY-MM-DD"),
        endDate : moment(picker.endDate).format("YYYY-MM-DD") ,
        loading: true 
      });
    }

    fileDownload = (url) => {
      firebase.storage()
        .refFromURL('gs://artience-datastore.appspot.com/data/' + url)
        .getDownloadURL()
        .then(function(downloadURL) {
        axios({
          url: downloadURL,
          method: 'GET',
          responseType: 'blob', // important
        }).then((response) => {
          const url2 = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url2;
          link.setAttribute('download', url); //or any other extension
          document.body.appendChild(link);
          link.click();
       });
      })
    }



    

  render() {
    var  renderData =  <Row>
      <Col md="12" lg="12">
        <table class="table table-sm"> 
          <thead>
            <tr style={{color : "white" }}>
              <th scope="col" data-key="table" class ="jsonColumn jsonColumn_Trackingcode">Table</th>
              <th scope="col">CreateTime</th>
              <th scope="col">UserName</th>
              <th scope="col">Period</th>
              <th scope="col">Dimension</th>
              <th scope="col">Metric</th>
              <th scope="col">Download</th>
            </tr>
          </thead>
          <tbody>
            {this.state.downList.map(({table,createTime,userName,startDate,endDate,fileName,dimensions,metrics,confirm},index) => (
              <tr>
                <td data-key="table" class ="jsonCell jsonCell_Trackingcode">{table}</td>
                <td>{moment(createTime).format("YYYY-MM-DD HH:mm")}</td>
                <td>{userName}</td>
                <td>{startDate} ~ {endDate} </td>
                <td>{dimensions} </td>
                <td>{metrics} </td>
                <td>

                  {confirm==="true" ?  <button 
                      onClick={() => this.fileDownload(fileName)}
                      value = {fileName}
                      className="form-control"
                      >
                        Download
                      </button>  : <Spinner size = {10} spinnerColor={"white"} spinnerWidth={2} visible={true} className="spinner-load"/>
                  }
                  
                </td>
              </tr>
            )
            )}
          </tbody>
        </table>
      </Col>
    </Row>

    var smallspiner = <Spinner spinnerColor={"white"} spinnerWidth={2} visible={true} className="spinner-load"/>

    var spinnerRender = <Row>
        <Col 
          md="12" 
          lg="12" 
          className="text-center" 
          style={{
          marginTop : "20px"}}
        >
          <h3>  Now Loading.. </h3>
          <div className="spinner-load">
            <Spinner size={200} spinnerColor={"white"} spinnerWidth={2} visible={true} className="spinner-load"/>
          </div>
        </Col>
    </Row>

    let modalClose = () => this.setState({ modalShow: false });

    return (
      <>
            <MyVerticallyCenteredModal
              isOpen={this.state.modalShow}
            />
        <div className="content">
          <Row>
            <Col xs="12" style ={paddingStyle}>
              <Card className="card-chart">
                <CardHeader style = {{paddingTop: "5px"}}>
                <Row>
                  <Col className="text-left" sm="6" lg="6" >
                    <div class="container titleName" style={{paddingLeft : "0px"}} >
                        <h3 className="card-category">{this.props.dbName}</h3>
                        <CardTitle tag="h1">Data Download</CardTitle>
                    </div>
                  </Col>
                </Row>
                </CardHeader>
                <Card>
                  <CardHeader style = {{paddingTop: "5px"}}>
                  {this.state.loading ? spinnerRender : renderData }
                  <Row>
                    <Col xs ="12" md="12" lg="12">
                      <div className="text-xs-center">
                        {/* <PaginationButton />  */}
                      </div>
                    </Col> 
                  </Row>       
                  </CardHeader>
                </Card>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
const DownloadContainer = () => (
  <SampleConsumer>
    {
      ({state, actions}) => (
        <Download
          dbName={state.dbName}
          setValue={actions.setValue}
        />
      )
    }
  </SampleConsumer>
)

export default DownloadContainer