import React from "react";
import { Modal , ModalHeader, ModalBody, ModalFooter,Button , Input} from 'reactstrap';



class MyVerticallyCenteredModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          modalShow: true ,
          unmountOnClose: true
        };
    
        this.toggle = this.toggle.bind(this);
      }
    
      toggle() {
        this.setState(prevState => ({
          modalShow: !prevState.modalShow
        }));
      }
    render() {
      return (
        <Modal isOpen={this.props.isOpen} className={this.props.className}>
          <ModalHeader >Report Save</ModalHeader>
          <ModalBody>
          <Input type="textarea" placeholder="  Please Input Report Name  " rows={1} />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}>Save</Button>{' '}
            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
      );
    }
  }

  export default MyVerticallyCenteredModal