import React from "react";
// nodejs library that concatenates classes
import Select from "react-select";
import moment from 'moment';
import axios from 'axios';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

// reactstrap components
import {
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";


import ReactTable from 'react-table'
import { SampleConsumer } from "../dbSelect";
var tabify = require('es-tabify');

// Be sure to include styles at some point, probably during your bootstrapping

// core components

const JsonTable = require('ts-react-json-table');

const createJsonDimension = (variable) => {
  const aggs = {}
  if (variable ==="Day") {
    aggs[variable] = {
      "terms": {
        "field": variable  ,
        "size": 100 ,
        "order": {
          "_key": "desc"
        }
      }
    }
  } else {
    aggs[variable] = {
      "terms": {
        "field": variable + ".keyword" ,
        "size": 100 ,
        "order": {
          "_key": "desc"
        }
      }
    }
  }
  return aggs
}

const createJsonMetric = (variable) => {
  var aggs = {}
  aggs[variable] = {
    "sum": {
      "field": variable
    }
  }
  return aggs
}

const createJsonMetric2 = (variable) => {
  var aggs = {
    "sum": {
      "field": variable
    }
  }
  return aggs
}


const makeQuery = ( dimension , metric ,startDate , endDate) => {
  var query = {}
  var beforeVariable = null
  var beforeVariableMetric = null
  var thisJson ;
  dimension.map( (index) => {
    if(beforeVariable === null) {
      query =  createJsonDimension(index["value"])
      beforeVariable = index["value"]
      thisJson = query
    } else {
      thisJson[beforeVariable]["aggs"] = createJsonDimension(index["value"])
      thisJson = thisJson[beforeVariable]["aggs"]
      beforeVariable = index["value"]
    }
  })

  metric.map( (index) => {
    if( beforeVariableMetric === null ) {
      thisJson[beforeVariable]["aggs"] =  createJsonMetric(index["value"])
      thisJson = thisJson[beforeVariable]["aggs"]
      beforeVariableMetric = index["value"]
    } else {
      thisJson[index["value"]] = createJsonMetric2(index["value"])
    }
  })

  var dateFilter = { "range" : 
    { "Day" : {
        "gte" : startDate ,
        "lt" : endDate
      } 
    }
  }

  var querySample = {
    "size": 0,
    "_source": {
      "excludes": []
    },
    "stored_fields": [
      "*"
    ],
    "script_fields": {},
    // "docvalue_fields": [
    //   {
    //     "field": "date",
    //     "format": "date_time"
    //   },
    //   {
    //     "field": "fields.date",
    //     "format": "date_time"
    //   }
    // ],
    "query": {
      "bool": {
        "must": [

        ],
        "filter": [
          {
            "match_all": {}
          }
        ],
        "should": [],
        "must_not": []
      }
    }
  }
  querySample["query"]["bool"]["must"].push(dateFilter)
  querySample["aggs"] = query
  return querySample;

}

const commaSplit = (srcNumber) =>  {
  var txtNumber = '' + srcNumber;
  var rxSplit = new RegExp('([0-9])([0-9][0-9][0-9][,.])');
  var arrNumber = txtNumber.split('.');
  arrNumber[0] += '.';
      do {
      arrNumber[0] = arrNumber[0].replace(rxSplit, '$1,$2');
      } while (rxSplit.test(arrNumber[0]));
      if (arrNumber.length > 1) {
      return arrNumber.join('');
      }
      else {
      return arrNumber[0].split('.')[0];
  }
}


const intMaker = (number) => {
    const cleanNumber = number * 100 
    return String(cleanNumber.toFixed(0)) + "%"
}

class UserManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      country: "All" , 
      week : "12" ,
      data : [] ,
      showing : true ,
      rawData : [] ,
      graphData : {
          "All": [] ,
          "UK": [] ,
          "US": [] ,
          "Australia" : [] ,
          "Germany" : []
      },
      media : "All" , 
      startDate : moment(new Date()).day(-30).format("YYYY-MM-DD") ,
      endDate : moment(new Date()).format("YYYY-MM-DD") ,
      dataColumn : [{dataField: "Day", text: "Day"}] ,
      totalResult : "" ,
      dataDimension : [] ,
      dataMetric : [] , 
      multiValueDimension: [],
      multiValueMetric: [],
    }
    this.handleMultiChangeDimension = this.handleMultiChangeDimension.bind(this);
    this.handleMultiChangeMetric = this.handleMultiChangeMetric.bind(this);
  }


  handleMultiChangeDimension(option) {
    this.setState(state => {
      return {
        multiValueDimension: option
      };
    });
    // console.log(Object.keys(this.state.multiValueDimension).length)
    try {
      this.elasticSearchAxios(
        makeQuery(option,this.state.multiValueMetric,this.state.startDate,this.state.endDate)
      )
    } catch(err) {
      console.log(err)
    }

  }
  handleMultiChangeMetric(option) {
    this.setState( state => {
      return {
        multiValueMetric: option
      };
    });
    this.elasticSearchAxios(
      makeQuery(this.state.multiValueDimension,option,this.state.startDate,this.state.endDate)
    )
  }

  elasticColumn(column){
    axios.get(
      "http://1.213.191.235:9200/" + this.props.dbName +"/", 
      { headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then(res =>
        {
          const dimension = []
          const metric = []
          const columnList = res["data"][this.props.dbName]["mappings"]["items"]["properties"]
          column.map((index)=> {
            if(columnList[index]["type"]==="text") {
              dimension.push({"value" : index , "label" : index})
            } else if (columnList[index]["type"]==="date") {
              dimension.push({"value" : index , "label" : index})
            } else {
              metric.push({"value" : index , "label" : index})
            }
          })
          this.setState({
            dataDimension : dimension ,
            dataMetric : metric
          })
        }
    );
  }

  elasticSearchAxios(query) {
        axios.post(
          "http://1.213.191.235:9200/" + this.props.dbName + "/_search", 
          query,
          { headers: {
              'Content-Type': 'application/json'
            }
          }
        ).then(res =>
            {
              if ( query !== null) {
                let tableData = []
                var rawData = res["data"]["aggregations"]
                const cleanData = tabify(res["data"])
                cleanData.map((index) => {
                  // console.log(index)
                  var data = {}
                  this.state.multiValueDimension.map((dimension) => {
                    if (dimension.value === "Day"){
                      data[dimension["value"]] = moment(index[dimension["value"]]).format("YYYY-MM-DD");
                    } else {
                      data[dimension["value"]] = index[dimension["value"]]
                    }
                  })
                  this.state.multiValueMetric.map((metric) => {
                    data[metric["value"]] = commaSplit(index[metric["value"]])
                  })
                  tableData.push(data)
                })
                this.setState({data: tableData 
                })
                // this.setState({totalResult : res["data"]["hits"]["total"]})
                console.log(rawData)

              } else {
                let tableData = []
                const postData = res["data"]["hits"]["hits"]
                this.setState({totalResult : res["data"]["hits"]["total"]})
                let column = []
                postData.map((index) => {
                  tableData.push(index["_source"])
                })
                Object.keys(tableData[0]).map((index) => {
                  column.push(index)
                })
                this.setState({data: tableData , 
                              dataColumn : column
                })
                this.elasticColumn(column)
              }
          }
        );
      }



    

    componentDidMount() {
      window.scrollTo(0, 0);
      //   this.elasticSearchAxios(
      //     makeQuery(this.state.multiValueDimension,this.state.multiValueMetric,this.state.startDate,this.state.endDate)
      // )
      this.elasticSearchAxios(
        null
      )
    } 

    componentDidUpdate(prevProps , prevState){
      if ( this.props.dbName !== prevProps.dbName){
        this.setState({
          multiValueDimension : [] , 
          multiValueMetric : []
        })
        this.elasticSearchAxios(
          null
        )
      }
    }

    handleEvent = (event, picker) => {
      this.setState({
        startDate : moment(picker.startDate).format("YYYY-MM-DD"),
        endDate : moment(picker.endDate).format("YYYY-MM-DD")
      });
      this.elasticSearchAxios(
          makeQuery(this.state.multiValueDimension,
                    this.state.multiValueMetric,
                    moment(picker.startDate).format("YYYY-MM-DD"),
                    moment(picker.endDate).format("YYYY-MM-DD")
                    )
      )


    }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col xs="12">
              <Card className="card-chart">
                <CardHeader>
                <Row>
                  <Col className="text-left" sm="6" lg="6">
                    <div class="container titleName" style={{paddingLeft : "0px"}} >
                        <h3 className="card-category">  </h3>
                        <CardTitle tag="h1">User Management</CardTitle>
                    </div>
                  </Col>
                  <Col sm="6" lg="6">
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                    <Col>
                            <Card>
                              <CardHeader>
                                  <p className="d-inline">Select User</p>
                              </CardHeader>
                              <CardBody>
                                  <ButtonGroup
                                      className="btn-group-toggle float-right"
                                      data-toggle="buttons"
                                  >
                                      <Col>
                                          <DateRangePicker 
                                              startDate={moment(this.state.startDate, "YYYY-MM-DD")} 
                                              endDate={moment(this.state.endDate, "YYYY-MM-DD")} 
                                              onApply = {this.handleEvent}
                                          >
                                              <button className="form-control">
                                                  {this.state.startDate}  ~ {this.state.endDate} 
                                              </button>
                                          </DateRangePicker>
                                      </Col>
                                  </ButtonGroup>
                              </CardBody>
                          </Card>
                    </Col>
                    </ButtonGroup>
                  </Col>
                </Row>
                </CardHeader>
                <Card>
                  <CardHeader>
                 
                  </CardHeader>
                  <CardHeader>
                    <Row>
                      <Col md="12" lg="12">
                      {/* <h5>  Total {commaSplit(this.state.totalResult)} Data Exist </h5>
                        <JsonTable scrollY 
                          className = "table table-striped table-bordered table-sm" 
                          rows = {this.state.data} 
                          style={{overflowX: 'scroll'}}
                        /> */}
                      </Col>
                    </Row>
                  </CardHeader>

                </Card>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const UserManagementContainer = () => (
  <SampleConsumer>
    {
      ({state, actions}) => (
        <UserManagement
          dbName={state.dbName}
          setValue={actions.setValue}
        />
      )
    }
  </SampleConsumer>
)

export default UserManagementContainer
