import React, { Component } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser , faKey } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.css'
import { Button , Col } from 'reactstrap';
import * as ROUTES from '../../constants/routes';

import { withFirebase } from '../firebase';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import gaPageview from '../googleAnalytics';
import * as ROLES from '../../constants/roles';

import {
    AuthUserContext,
    withAuthorization,
    withEmailVerification,
  } from '../sessions';

import '../../assets/css/login.css'

library.add(faUser)
library.add(faKey)

const SignUpPage = () => (
    <SignUpForm/>
  );

const INITIAL_STATE = {
    email: '',
    password: '',
    error: null,
    username: '',
    isAdmin: false,
};

const ERROR_CODE_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

  class SignUpFormBase extends Component {
    constructor(props) {
      super(props);
      this.state = { ...INITIAL_STATE };
    }
  
    onSubmit = event => {
      const { email, password } = this.state;
      this.props.firebase
        .doCreateUserWithEmailAndPassword(email, password)
        // .then(returnedUser => {

        //   // Create a user in your Firebase Realtime Database too
        //   return this.props.firebase.user(returnedUser.user.uid).set({
        //     username: "Hello11",
        //     email: returnedUser.user.email,
        //     roles: ADMIN
        //     time : new Date()
        //   });
        // })
        .then(() => {
          this.setState({ ...INITIAL_STATE });
          this.props.history.push(ROUTES.EXPLORE);
        })
        .catch(error => {
          this.setState({ error });
        });
      event.preventDefault();
    };
  
    onChange = event => {
      this.setState({ [event.target.name]: event.target.value });
    };
    
    componentDidMount(){
        gaPageview()
    }
    render() {
      const { email, password, error } = this.state;
  
      const isInvalid = password === '' || email === '';
  
      return (
        <div className ="container">
        <Col sm="12" lg ="4" md="6">
            <div>
                <div className="d-flex justify-content-center h-100 login-page">
                    <div className="card">
                        <div className="card-header">
                            <h3>Artience Datastore</h3>
                            <h4>Sign-UP</h4>
                        </div>
                        <div className ="card-body">
                            <form onSubmit={this.onSubmit}> 
                                <label> E-Mail</label>
                                <div className="input-group form-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"
                                            style={{paddingRight: '15px'}}>
                                            <FontAwesomeIcon icon="user" />
                                        </span>
                                    </div>
                                        <input 
                                            type = "text" 
                                            name = "email"
                                            className = "form-control" 
                                            onChange = {this.onChange}
                                            value = {email}
                                            placeholder="Email Address (ex: yhkim@artience.co.kr)" 
                                        />
                                    </div>
                                <label> Password </label>
                                <div className="input-group form-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"
                                            style={{paddingRight: '15px'}}>
                                            <FontAwesomeIcon icon="key" />
                                        </span>
                                    </div>
                                    <input 
                                        type="password" 
                                        name ="password"
                                        className="form-control" 
                                        onChange = {this.onChange}
                                        value = {password}
                                        placeholder="password"/>
                                </div>
                                <div className="form-group">
                                    <h5 style ={{
                                        paddingLeft : "10px"
                                    }}><a href="/">LogIn</a></h5>
                                    <Button 
                                        color="success" 
                                        className="btn float-right login-btn" 
                                        type ="submit">Join
                                    </Button>{' '}
                                </div>
                                {error && <p>{error.message}</p>}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </Col>
        </div>
      );
    }
  }


//   const condition = authUser => authUser && authUser.roles.includes(ROLES.ADMIN);
//   const condition = authUser => !!authUser;

  const SignUpForm = compose(
      withRouter,
      withFirebase,
    //   withAuthorization(condition),
  )(SignUpFormBase)

  export default SignUpPage;
  export { SignUpForm }