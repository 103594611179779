import React from "react";
// nodejs library that concatenates classes
import Select from "react-select";
import moment from 'moment';
import axios from 'axios';
import MyVerticallyCenteredModal from './modal'
import ModalExport from './modalExport'
import ModalSample from './modalSample'


import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import ReactExport from "react-data-export";

// reactstrap components
import {
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button
} from "reactstrap";

import Spinner from 'react-spinner-material';

import { SampleConsumer } from "../dbSelect";
import PaginationButton from './PaginationButton';

const paddingStyle = { 
  paddingLeft: "5px" ,
  paddingRight: "5px"
}

;


const JsonTable = require('ts-react-json-table');

const createJsonDimension = (variable) => {
  const aggs = {}
  if (variable ==="Day") {
    aggs[variable] = {
      "terms": {
        "field": variable  ,
        "size": 100 ,
        "order": {
          "_key": "desc"
        }
      }
    }
  } else {
    aggs[variable] = {
      "terms": {
        "field": variable + ".keyword" ,
        "size": 100 ,
        "order": {
          "_key": "desc"
        }
      }
    }
  }
  return aggs
}

const createJsonMetric = (variable) => {
  var aggs = {}
  aggs[variable] = {
    "sum": {
      "field": variable
    }
  }
  return aggs
}

const createJsonMetric2 = (variable) => {
  var aggs = {
    "sum": {
      "field": variable
    }
  }
  return aggs
}

const makeQuery = ( dimension , metric ,startDate , endDate , pageNum ) => {
    var query = {}
    query["dimensions"] = dimension
    query["metrics"] = metric
    query["startDate"] = startDate
    query["endDate"] = endDate
    query["pageNum"] = pageNum
    query["csrfToken"] = sessionStorage.getItem('csrfToken').replace('"','').replace('"','')
    query["userName"] = JSON.parse(sessionStorage.getItem('authUser'))["username"]
  
    return query
}

const commaSplit = (srcNumber) =>  {
  var txtNumber = '' + srcNumber;
  var rxSplit = new RegExp('([0-9])([0-9][0-9][0-9][,.])');
  var arrNumber = txtNumber.split('.');
  arrNumber[0] += '.';
      do {
      arrNumber[0] = arrNumber[0].replace(rxSplit, '$1,$2');
      } while (rxSplit.test(arrNumber[0]));
      if (arrNumber.length > 1) {
      return arrNumber.join('');
      }
      else {
      return arrNumber[0].split('.')[0];
  }
}


const intMaker = (number) => {
    const cleanNumber = number * 100 
    return String(cleanNumber.toFixed(0)) + "%"
}




class Explore extends React.Component {
  constructor(props) {
    super(props);
    var onChangePage  = this.onChangePage.bind(this);
    var elasticSearchAxios  = this.elasticSearchAxios.bind(this);
    this.state = {
      week : "12" ,
      data : [] ,
      showing : true ,
      rawData : [] ,
      startDate :   moment(new Date()).day(-30).format("YYYY-MM-DD") ,
      endDate : moment(new Date()).format("YYYY-MM-DD") ,
      dataColumn : [{dataField: "Day", text: "Day"}] ,
      totalResult : 0 ,
      dataDimension : [] ,
      dataMetric : [] , 
      multiValueDimension: [],
      multiValueMetric: [],
      pageNum : 1 , 
      loading : false  , 
      modalShow: false  ,
      userName : sessionStorage.getItem("authUser")["username"], 
      modalExportShow : false , 
      linkState : undefined
    }
    this.handleMultiChangeDimension = this.handleMultiChangeDimension.bind(this);
    this.handleMultiChangeMetric = this.handleMultiChangeMetric.bind(this);
  }


  handleMultiChangeDimension(option) {
    this.setState(state => {
      return {
        multiValueDimension: option
      };
    });
    try {
    } catch(err) {
      console.log(err)
    }

  }
  handleMultiChangeMetric(option) {
    this.setState( state => {
      return {
        multiValueMetric: option
      };
    });
  }

    elasticColumn() {
      axios.post(
        process.env.REACT_APP_REQUEST_URL + "/db/select/column/" + this.props.dbName +"/" ,
        { 
          csrfToken : sessionStorage.getItem('csrfToken').replace('"','').replace('"','')
        }
      ).then(res =>
          { 
            const dimension = []
            const metric = []
            const columnList = res["data"][0]
            columnList.map((index)=> {
              if(index["DATA_TYPE"] === "varchar") {
                dimension.push({"value" : index["COLUMN_NAME"] , "label" : index["COLUMN_NAME"] })
              } else if (index["DATA_TYPE"] === "date") {
                dimension.push({"value" : index["COLUMN_NAME"] , "label" : index["COLUMN_NAME"]})
              } else {
                metric.push({"value" : index["COLUMN_NAME"] , "label" : index["COLUMN_NAME"] })
              }
            })
            this.setState({
              dataDimension : dimension ,
              dataMetric : metric
            })
          }).catch((error) => {
            console.log("Error")
        })
      }




  downloadRequest(query) {
      axios.post(
        process.env.REACT_APP_REQUEST_URL + "/db/select/download/" + this.props.dbName + "/", 
        query ,
        { headers: {
            'Content-Type': 'application/json' ,
            // Cookie : "csrfToken=" + sessionStorage.getItem('csrfToken') + ";"
          }
        }
      ).then(res => {
        console.log(res)
      }).catch((error) => {
        console.log("Error")
    })
  }

  rowAxios(query) {
      axios.post(
        process.env.REACT_APP_REQUEST_URL + "/db/select/length/" + this.props.dbName + "/", 
        query ,
        { headers: {
            'Content-Type': 'application/json' ,
            // Cookie : "csrfToken=" + sessionStorage.getItem('csrfToken') + ";"
          }
        }
      ).then(res => {
        this.elasticSearchAxios(query)
        const rowLength = res["data"][0][0]["count"]
        this.setState({
          totalResult : rowLength 
        })
      }).catch((error) => {
        console.log("Error")
    })
  }

  onChangePage(page,axiosRequest) {
    window.scrollTo(0, 0);
    this.setState({pageNum:page })
    this.setState({loading:true })


    axiosRequest(
      makeQuery(
        this.state.multiValueDimension,
        this.state.multiValueMetric,
        moment(this.state.startDate).format("YYYY-MM-DD"),
        moment(this.state.endDate).format("YYYY-MM-DD"), 
        page
      )
    )
    
}

  elasticSearchAxios(query) {
        axios.post(
          process.env.REACT_APP_REQUEST_URL + "/db/select/data/" + this.props.dbName + "/", 
          query ,
          { headers: {
              'Content-Type': 'application/json' ,
              // Cookie : "csrfToken=" + sessionStorage.getItem('csrfToken') + ";"
            }
          }
        ).then(res =>
            {
              this.setState({ loading: false })
              if ( (this.state.multiValueMetric).length >= 1 && (this.state.multiValueDimension).length >= 1 ) {
                let tableData = []
                const postData = res["data"][0]
                const columnList = []

                this.state.multiValueDimension.map((col) => {
                  columnList.push(col)
                })
                this.state.multiValueMetric.map((col) => {
                  columnList.push(col)
                })

                postData.map((index) => {
                    this.state.multiValueDimension.map((col) => {
                      if(col.value==="Day") {
                        index[col.value] = moment(index[col.value]).format("YYYY-MM-DD")
                      }
                    })
                    this.state.multiValueMetric.map((col) => {
                      if(col.value==="Cost" || col.value==="Spend") {
                        index[col.value] = commaSplit((index[col.value]).toFixed(2))
                      } else {
                        index[col.value] = commaSplit(index[col.value])
                      }
                    })

                    tableData.push(index)
                })

                this.setState({
                  data : tableData  ,
                  dataColumn : columnList
                })

              } else {
                
                let tableData = []
                const postData = res["data"][0]
                const columnList = []
                this.state.dataDimension.map((col) => {
                  columnList.push(col)
                })
                this.state.dataMetric.map((col) => {
                  columnList.push(col)
                })
                postData.map((index) => {
                    if(index["Day"]) {
                      index["Day"] = moment(index["Day"]).format("YYYY-MM-DD")
                    }
                    this.state.dataMetric.map((col) => {
                      if(col.value === "Cost" || col.value === "Spend") {
                        if(index[col.value]===undefined) {} 
                          else {
                            index[col.value] = commaSplit((index[col.value]).toFixed(2))
                          }
                      } else {
                        index[col.value] = commaSplit(index[col.value])
                      }
                    })
                    tableData.push(index)

                })
                this.setState({
                  data: tableData ,
                  dataColumn : columnList
                })
              }
          }
        ).catch((error) => {
          console.log("Error")
      })
      }

      dataRefresh() {
        this.setState({ loading: true })
        this.rowAxios(
          makeQuery(this.state.multiValueDimension,this.state.multiValueMetric,this.state.startDate,this.state.endDate,this.state.pageNum)
        )
        // this.setState({ loading: false })
        // this.rowAxios(
        //   makeQuery(this.state.multiValueDimension,this.state.multiValueMetric,this.state.startDate,this.state.endDate,this.state.pageNum)
        // )
      }

      _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          this.dataRefresh();
        }
      }

    componentDidMount() {
      this.setState({ loading: true })
      window.scrollTo(0, 0);

      if(this.state.linkState !== this.props.location.state  ) {
        this.setState({
          linkState : this.props.location.state ,
          multiValueDimension : this.props.location.state.dimensions , 
          multiValueMetric : this.props.location.state.metrics        
        })
        this.rowAxios(
          makeQuery(
            this.props.location.state.dimensions,
            this.props.location.state.metrics ,
            moment(this.state.startDate).format("YYYY-MM-DD"),
            moment(this.state.endDate).format("YYYY-MM-DD"),
            this.state.pageNum
          )
        )
        this.elasticColumn()
      } else {
      this.rowAxios(
        makeQuery(
          [],
          [],
          moment(this.state.startDate).format("YYYY-MM-DD"),
          moment(this.state.endDate).format("YYYY-MM-DD") ,
          this.state.pageNum
        )
      )
      this.elasticColumn()
      }


    } 

    componentDidUpdate(prevProps , prevState){

      if ( this.props.dbName !== prevProps.dbName){
        this.setState({
          multiValueDimension : [] , 
          multiValueMetric : [] , 
          loading: true 
        })
        this.rowAxios(
          makeQuery(
            [],
            [],
            moment(this.state.startDate).format("YYYY-MM-DD"),
            moment(this.state.endDate).format("YYYY-MM-DD"),
            this.state.pageNum
          )
        )
        this.elasticColumn()
      }
    }

    handleEvent = (event, picker) => {
      this.setState({
        startDate : moment(picker.startDate).format("YYYY-MM-DD"),
        endDate : moment(picker.endDate).format("YYYY-MM-DD") ,
        loading: true 
      });
      this.rowAxios(
        makeQuery(
          this.state.multiValueDimension,
          this.state.multiValueMetric,
          moment(picker.startDate).format("YYYY-MM-DD"),
          moment(picker.endDate).format("YYYY-MM-DD") ,
          this.state.pageNum
        )
      )
    }





    

  render() {
    var onChangePage = this.onChangePage
    var elasticSearchAxios = this.elasticSearchAxios
    var {totalResult,data} = this.state
    var  renderData =  <Row>
      <Col md="12" lg="12">
      <h5>  Total { commaSplit(this.state.totalResult) } Data Exist </h5>
        <JsonTable scrollY 
          className = "table table-striped table-bordered table-sm" 
          rows = { this.state.data } 
          style={{ overflowX: 'scroll' }}
        />
      </Col>
    </Row>


    var spinnerRender = <Row>
        <Col 
          md="12" 
          lg="12" 
          className="text-center" 
          style={{
          marginTop : "20px"}}
        >
          <h3>  Now Loading.. </h3>
          <div className="spinner-load">
            <Spinner size={200} spinnerColor={"white"} spinnerWidth={2} visible={true} className="spinner-load"/>
          </div>
        </Col>
    </Row>

    let modalClose = () => this.setState({ modalShow: false });
    let modalExportClose = () => this.setState({ modalExportShow: false });

    return (
      <>

            <ModalExport
              isOpen={this.state.modalExportShow}
            />
            <MyVerticallyCenteredModal
              isOpen={this.state.modalShow}
            />

        <div className="content">
          <Row>
            <Col xs="12" style ={paddingStyle}>
              <Card className="card-chart">
                <CardHeader style = {{paddingTop: "5px"}}>
                <Row>
                  <Col className="text-left" sm="6" lg="6" >
                    <div class="container titleName" style={{paddingLeft : "0px"}} >
                        <h3 className="card-category">{this.props.dbName}</h3>
                        <CardTitle tag="h1">Data Explorer</CardTitle>
                    </div>
                  </Col>
                  <Col sm="6" lg="6" style ={paddingStyle}>
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                    <Col style ={paddingStyle}>
                      <Card>
                          <CardHeader>
                              <p className="d-inline">Select Date Range</p>
                          </CardHeader>
                          <CardBody>
                              <ButtonGroup
                                  className="btn-group-toggle float-right"
                                  data-toggle="buttons"
                              >
                                  <Col style = {{
                                      paddingLeft: "0px" ,
                                      paddingRight: "0px" ,
                                  }}>
                                      <DateRangePicker 
                                          startDate={moment(this.state.startDate, "YYYY-MM-DD")} 
                                          endDate={moment(this.state.endDate, "YYYY-MM-DD")} 
                                          onApply = {this.handleEvent}
                                      >
                                          <button className="form-control">
                                              {this.state.startDate}  ~ {this.state.endDate} 
                                          </button>
                                      </DateRangePicker>
                                  </Col>
                              </ButtonGroup>
                          </CardBody>
                      </Card>
                    </Col>
                    </ButtonGroup>
                  </Col>
                </Row>
                </CardHeader>
                <Card>
                  <CardHeader>
                  <Row>
                  <Col className="text-left" sm="10" lg="10" >
                    <CardTitle tag="h4">Group by & Summarize</CardTitle>
                  </Col>
                  <Col sm="2" lg="2" >

                  <ButtonGroup
                      className="float-right"
                      data-toggle="buttons" style={{width : "100%"}}
                    >
                      {/* <button onClick={() => {this.setState({ modalShow: true });
                                              // setTimeout(function() {
                                              //   this.setState({ modalShow: false });
                                              //   }.bind(this), 1000);
                                            }}

                              className ="form-control"
                              style = {{
                                marginBottom : "0px" ,
                                paddingLeft: "3px" , 
                                paddingRight: "3px" ,
                                marginRight : "5px"
                            }}
                              >
                        Save
                      </button> */}

                      <ModalSample className ="form-control" 
                                    buttonLabel="Save"
                                    style = {{
                                      marginBottom : "0px" ,
                                      paddingLeft: "3px" , 
                                      paddingRight: "3px" ,
                                      marginRight : "5px"
                                    }}
                                    dimensions = {this.state.multiValueDimension}
                                    metrics = {this.state.multiValueMetric}
                                    dbNames = {this.props.dbName}
                                    />
                      {/* {excel} */}
                      <button onClick={() => {this.setState({ modalExportShow: true });
                                              this.downloadRequest(
                                                makeQuery(
                                                  this.state.multiValueDimension,
                                                  this.state.multiValueMetric,
                                                  moment(this.state.startDate).format("YYYY-MM-DD"),
                                                  moment(this.state.endDate).format("YYYY-MM-DD"), 
                                                  this.state.pageNum
                                                )
                                              );
                                              setTimeout(function() {
                                                this.setState({ modalExportShow: false });
                                                }.bind(this), 2000);
                                            }}

                              className ="form-control"
                              style = {{
                                marginBottom : "0px" ,
                                paddingLeft: "3px" , 
                                paddingRight: "3px" ,
                                marginRight : "5px"
                            }}
                              >
                        Export
                      </button>
                    </ButtonGroup>
                  </Col>
                  </Row>

                  <Row>
                  <Col sm ="12" md="5" lg="5" >
                    <label>Dimension</label>
                    <Select
                      name="dimension"
                      placeholder="Select Dimension"
                      value={this.state.multiValueDimension}
                      options={this.state.dataDimension}
                      onChange={this.handleMultiChangeDimension}
                      isMulti ={true}
                      styles ={{color : "black"}}
                      onKeyDown={this._handleKeyDown}
                    />
                  </Col>
                  <Col sm ="12" md="5" lg="5" >
                    <label>Metric</label>
                    <Select
                      name="filters"
                      placeholder="Filters"
                      value={this.state.multiValueMetric}
                      options={this.state.dataMetric}
                      onChange={this.handleMultiChangeMetric}
                      isMulti ={true}
                      onKeyDown={this._handleKeyDown}
                    />
                  </Col>
                  <Col sm ="12" md="2" lg="2" >
                  <ButtonGroup style={{width:"100%"}}>
                  <button  onClick ={this.dataRefresh.bind(this)}
                            className ="form-control"
                            style = {{
                              marginTop: "26px" , 
                              marginBottom : "0px" ,
                              paddingLeft: "3px" , 
                          }}
                            >
                      Refresh
                    </button>
                  </ButtonGroup>
                  
                  </Col>
                  </Row>
                  </CardHeader>


                  <CardHeader style = {{paddingTop: "5px"}}>
                  {this.state.loading ? spinnerRender : renderData }
                  <Row>
                    <Col xs ="12" md="12" lg="12">
                      <div className="text-xs-center">
                        <PaginationButton 
                          length={Number(this.state.totalResult)} 
                          onChangePage={this.onChangePage.bind(this)} 
                          axiosRequest = {this.elasticSearchAxios.bind(this)}
                          page = {this.state.pageNum}
                        /> 
                      </div>
                    </Col> 
                  </Row>       
                  </CardHeader>
                </Card>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
// const exploreContainer = () => (
//   <SampleConsumer>
//     {
//       ({state, actions}) => (
//         <Explore
//           dbName={state.dbName}
//           setValue={actions.setValue}
//         />
//       )
//     }
//   </SampleConsumer>
// )


class exploreContainer extends React.Component {
  render() {
    return (
      <SampleConsumer>
      {
        ({state, actions}) => (
          <Explore
            dbName={state.dbName}
            setValue={actions.setValue}
            {...this.props}
          />
        )
      }
    </SampleConsumer>
    )
    }
  }
 


export default exploreContainer