import React from "react";
import { Modal , ModalHeader, ModalBody, ModalFooter,Button} from 'reactstrap';



class ModalExport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          modal: true
        };
    
        this.toggle = this.toggle.bind(this);
      }
    
      toggle() {
        this.setState(prevState => ({
          modal: !prevState.modal
        }));
      }
    render() {
      return (
        <Modal isOpen={this.props.isOpen} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>Download Request is Success!</ModalHeader>
          <ModalBody>
            You can check it on the Download tab.
          </ModalBody>
          <ModalFooter>
            {/* <Button color="primary" onClick={this.toggle}>Do Something</Button>{' '}
            <Button color="secondary" onClick={this.toggle}>Cancel</Button> */}
          </ModalFooter>
        </Modal>
      );
    }
  }

  export default ModalExport