// import React from "react";
// nodejs library that concatenates classes
// import classNames from "classnames";


import ReactGA from 'react-ga';

const gaPageview = () => {
    ReactGA.initialize('UA-136114010-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
}

export default gaPageview