import React from 'react';
import '../../assets/css/notFound.css'
// import { withRouter } from 'react-router-dom';
import { Col , Row } from "reactstrap";
import gaPageview from '../googleAnalytics';

class commingSoon extends React.Component { 

    componentDidMount(){
        gaPageview()
    }
    render(){
        return(
            <div className="content">
                <Row className="h-100">
                <Col className="my-auto" md="10" lg="10" style={{marginLeft:"auto" , marginRight:"auto"}} >
                <div class="notfound">
                    <div class="notfound-404">
                        <div >
                            <h3>We are Working Hard</h3>
                        <h1>
                            <span>4</span>
                            <span>0</span>
                            <span>4</span>
                        </h1>
                        </div>
                        <h3>Coming Soon!</h3>
                    </div>
                </div>
                </Col>
                </Row>
            </div>


        )
    }
}

export default commingSoon