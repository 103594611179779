import React, { Component } from 'react';
import { BrowserRouter as Router, Route ,Switch , Link , withRouter} from 'react-router-dom';

import logInPage from './components/login';
import SignUpPage from './components/signup';
// import  from './components/dashboard';

import * as ROUTES from './constants/routes';

import AdminPage from "./components/layout";

import "./assets/scss/black-dashboard-react.scss";
import "./assets/demo/demo.css";
import "./assets/css/nucleo-icons.css";

import { withAuthentication } from './components/sessions';
import { SampleProvider } from './components/dbSelect';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users : JSON.parse(localStorage.getItem('authUser'))
    }
  }

  componentDidMount(){

  }
  render() {
    return (
      
        <Router>
            <Switch>
          <div>
            <Route exact path={ROUTES.SIGN_IN} component={logInPage} />
            <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
            <SampleProvider >
              <Route path={ROUTES.HOME} component={AdminPage} />
            </SampleProvider>
          </div>
          </Switch>
        </Router>
    );
  }
}

export default withAuthentication(App);
