import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import axios from 'axios';

const config = {
    apiKey: "AIzaSyCYzyZuUDUvpzVVg7rIIIZe3osZ5vRyM0E",
    authDomain: "artience-datastore.firebaseapp.com",
    databaseURL: "https://artience-datastore.firebaseio.com",
    projectId: "artience-datastore",
    storageBucket: "artience-datastore.appspot.com",
    messagingSenderId: "772878545320"
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    /* Helper */

    this.serverValue = app.database.ServerValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.database();


    /* Social Sign In Method Provider */

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    this.twitterProvider = new app.auth.TwitterAuthProvider();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) => 
  this.auth.createUserWithEmailAndPassword(email, password).then( user => {
    console.log(user)
  });
  

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password).then( user => {
      sessionStorage.setItem('csrfToken', JSON.stringify(user.user.ra));
    });

  doSignInWithGoogle = () =>
    this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () =>
    this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () =>
    this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut().then( () =>
    {
      sessionStorage.removeItem('csrfToken');
    }
  )

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      // url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
      url : "http://localhost:3000"
    });

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .once('value')
          .then(snapshot => {
            const dbUser = snapshot.val();
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              crsfToken : authUser.stsTokenManager ,
              ...dbUser,
            };
            next(authUser);
          });
      } else {
        fallback();
      }
    });

    // onAuthUserListener = (next, fallback) => {
    //   axios.post(
    //     process.env.REACT_APP_REQUEST_URL + "/db/auth/check/" ,
    //     { 
    //       csrfToken : sessionStorage.getItem('csrfToken').replace('"','').replace('"','')
    //     }
    //   ).then(res => {
    //     if(res.auth==="pass") {
    //       this.auth.onAuthStateChanged(authUser => {
    //             console.log(authUser)
    //             if (authUser) {
    //               this.user(authUser.uid)
    //                 .once('value')
    //                 .then(snapshot => {
    //                   const dbUser = snapshot.val();
    //                   authUser = {
    //                     uid: authUser.uid,
    //                     email: authUser.email,
    //                     emailVerified: authUser.emailVerified,
    //                     providerData: authUser.providerData,
    //                     crsfToken : authUser.stsTokenManager ,
    //                     ...dbUser,
    //                   };
    //                   next(authUser);
    //                 });
    //             } else {
    //               fallback();
    //             }
    //           });
    //     } else {
    //       fallback()
    //     }
    //   }
    //   )}

  // *** User API ***

  user = uid => this.db.ref(`users/${uid}`);

  users = () => this.db.ref('users');


  // *** Message API ***

  message = uid => this.db.ref(`messages/${uid}`);

  messages = () => this.db.ref('messages');
}

export default Firebase;
